import "core-js/modules/es.function.name.js";
import { qn_token_get } from '../../api/qiniuApI';
export default {
  name: "UploadLogo",
  props: ['cover', 'isHideDescribe', 'row'],
  data: function data() {
    return {
      isUploading: false,
      action: "",
      imageUrl: "",
      data: {
        token: '',
        key: '',
        fname: ''
      },
      tokenData: {}
    };
  },
  created: function created() {
    this.imageUrl = this.cover;
    this.getToken();
  },
  methods: {
    //获取token
    getToken: function getToken() {
      var _this = this;
      // console.log("token1111111")
      qn_token_get().then(function (res) {
        _this.tokenData = res;
        _this.data.token = _this.tokenData.token;
        // if (process.env.NODE_ENV == "production"){
        //   //正式
        //   this.action="https://upload.qiniup.com/"
        //   // this.action="https://upload-z1.qiniup.com/" //测试
        // }else{
        //   //测试
        //   this.action="https://upload-z1.qiniup.com/"
        // }
        _this.action = process.env.VUE_APP_QINIU_UPLOAD;
      });
    },
    //上传前
    beforeAvatarUpload: function beforeAvatarUpload(rawFile) {
      this.data.key = "resource/logo/" + new Date().getTime() + "/" + rawFile.name;
      this.data.fname = rawFile.name;
      //是否正在上传
      this.isUploading = true;
    },
    //上传完毕
    handleAvatarSuccess: function handleAvatarSuccess(response, uploadFile) {
      // this.logo = URL.createObjectURL(uploadFile.raw)
      //上传完毕 不展示loading
      this.isUploading = false;
      this.imageUrl = this.tokenData.domain + "/" + response.key;
      this.$emit("setLogo", this.imageUrl, this.row);
    }
  },
  mounted: function mounted() {}
};