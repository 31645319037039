import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import Index from "../views/index/Index";
const Config = require("../../main.config");

import proEnv from "../../config/pro.env"; // 生产环境
import uatEnv from "../../config/uat.env"; // 生产环境
let baseUrl = "";
// 环境的切换，环境可能有开发环境、测试环境和生产环境
if (process.env.NODE_ENV == "development") {
  baseUrl = "/api";
} else if (process.env.NODE_ENV == "production") {
  baseUrl = proEnv.hosturl;
} else if (process.env.NODE_ENV == "test") {
  baseUrl = uatEnv.hosturl;
}

const routes = [
  // 首页
  {
    path: "/",
    //redirect: "/login",
    redirect: "/index",
    hidden: true,
  },
  {
    path: "/index",
    name: "Index",
    component: Index,
    hidden: false,
    meta: {
      title: "首页",
    },
  },
  {
    //测试
    path: "/test",
    name: "test",
    component: () => import("@/views/test"),
    hidden: true,
  },
  //404
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/Error'),
    hidden: true
  },
  {
    //浏览富文本编辑页面
    path: "/view/editor",
    name: "viewEditor",
    component: () => import("@/views/editor/ViewEditor"),
    hidden: true,
  },
  //登录
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/user/Login"),
    hidden: true,
  },

  //系统（原来名字-系统管理）
  {
    path: "/system",
    name: "SystemIndex",
    component: () => import("@/views/system/Index"),
    hidden: false,
    meta: {
      title: "系统",
    },
    redirect: "/system/role",
    children: [
      {
        path: "/system/role",
        name: "Role",
        meta: {
          show: true,
          icon: "avatar",
          title: "角色管理",
          activeMenu: "/system",
          nowActiveMenu: "/system/role",
          nowMenu: "/system/role",
        },
        component: () => import("@/views/system/Role"),
      },
      {
        path: "/system/manage",
        name: "SystemManage",
        meta: {
          show: false,
          title: "角色权限管理",
          activeMenu: "/system",
          // nowActiveMenu: "/system/manage",
          // nowMenu: "/system/manage",
        },
        redirect: "/system/manage/role",
        component: () => import("@/views/system/Manage"),
        children: [
          {
            path: "/system/manage/role",
            name: "RolePermission",
            meta: {
              show: true,
              title: "角色权限",
              activeMenu: "/system",
              nowActiveMenu: "/system/manage/role",
              nowMenu: "/system/role",
            },
            component: () => import("@/views/system/RolePermission"),
          }
        ],
      },
      {
        path: "/system/funcgroup",
        name: "FunctionGroup",
        meta: {
          show: true,
          icon: "menu",
          title: "功能组管理",
          activeMenu: "/system",
          nowActiveMenu: "/system/funcgroup",
          nowMenu: "/system/funcgroup",
        },
        component: () => import("@/views/system/FunctionGroup"),
      },
      {
        path: "/system/function",
        name: "Function",
        meta: {
          show: true,
          icon: "grid",
          title: "功能管理",
          activeMenu: "/system",
          nowActiveMenu: "/system/function",
          nowMenu: "/system/function",
        },
        component: () => import("@/views/system/Function"),
      },
      {
        path: "/system/stdlevel",
        name: "StdLevel",
        meta: {
          show: true,
          icon: "soldout",
          title: "标准结构管理",
          activeMenu: "/system",
          nowActiveMenu: "/system/stdlevel",
          nowMenu: "/system/stdlevel",
        },
        component: () => import("@/views/system/StdLevel"),
      },
      {
        path: "/system/materialCode",
        name: "MaterialCode",
        meta: {
          show: true,
          icon: "doccopy",
          title: "物料编码管理",
          activeMenu: "/system",
          nowActiveMenu: "/system/materialCode",
          nowMenu: "/system/materialCode",
        },
        component: () => import("@/views/system/MaterialCode"),
      },
      {
        path: "/system/log",
        name: "Log",
        meta: {
          show: true,
          icon: "ScaleToOriginal",
          title: "日志管理",
          activeMenu: "/system",
          nowActiveMenu: "/system/log",
          nowMenu: "/system/log",
        },
        component: () => import("@/views/system/Log"),
      },
    ],
  },

  // 课程 （原来名字-课程中心）
  {
    path: "/course",
    name: "CourseIndex",
    hidden: false,
    meta: {
      title: "课程",
      showInbreadcrumb: true,
    },
    component: () => import("@/views/course/Index"),
    redirect: "/course/list",
    children: [
      // 课程中心
      {
        path: "/course/courseCenter",
        meta: {
          show: true,// 是否显示
          icon: "course",
          title: "课程中心", // 显示的名字
          activeMenu: "/course", // 高亮的顶部菜单
        },
        redirect: "/course/list",
        component: () => import("@/views/course/SubIndex"),
        children: [
          {
            path: "/course/list",
            name: "CourseList",
            meta: {
              show: true,
              title: "课程管理",
              activeMenu: "/course",
              nowActiveMenu: "/course/list",
              nowMenu: "/course/list",
            },
            component: () => import("@/views/course/CourseList"),
          },
          {
            path: "/course/resourcetype",
            name: "CourseResourceType",
            meta: {
              show: true,
              title: "资源类型管理",
              activeMenu: "/course",
              nowActiveMenu: "/course/resourcetype",
              nowMenu: "/course/resourcetype",
            },
            component: () => import("@/views/course/CourseResourceType"),
          },
          {
            path: "/course/filetype",
            name: "CourseFileType",
            meta: {
              show: true,
              title: "文件类型管理",
              activeMenu: "/course",
              nowActiveMenu: "/course/filetype",
              nowMenu: "/course/filetype",
            },
            component: () => import("@/views/course/CourseFileType"),
          },
          {
            path: "/course/type",
            name: "CourseType",
            meta: {
              show: true,
              title: "课程类型管理",
              activeMenu: "/course",
              nowActiveMenu: "/course/type",
              nowMenu: "/course/type",
            },
            component: () => import("@/views/course/CourseType"),
          },{
            path: "/activity/type",
            name: "ActivityType",
            meta: {
              show: true,
              title: "活动类型管理",
              activeMenu: "/course",
              nowActiveMenu: "/activity/type",
              nowMenu: "/activity/type",
            },
            component: () => import("@/views/course/ActivityType"),
          }
        ],
      },
      // 课程中心-课程管理-结构管理
      {
        path: "/course/structure",
        name: "CourseStructure",
        meta: {
          show: false,
          title: "结构管理",
          activeMenu: "/course",
          nowActiveMenu: "/course/structure",
          nowMenu: "/course/list",
        },
        component: () => import("@/views/course/CourseStructure"),
      },
      // 课程中心-课程管理-版本管理
      {
        path: "/course/version",
        name: "CourseVersion",
        meta: {
          show: false,
          title: "版本管理",
          activeMenu: "/course",
          nowActiveMenu: "/course/version",
          nowMenu: "/course/list",
        },
        component: () => import("@/views/course/CourseVersion.vue"),
      },
      // 课程中心-课程管理-结构管理-层级管理
      {
        path: "/course/edit/structure",
        name: "StructureEdit",
        meta: {
          show: false,
          title: "层级管理",
          activeMenu: "/course",
          nowActiveMenu: "/course/edit/structure",
          nowMenu: "/course/list",
        },
        component: () => import("@/views/course/StructureEdit.vue"),
      },
      // 课程中心-课程管理-资源管理
      {
        path: "/course/edit/courseResource",
        name: "EditCourseResource",
        meta: {
          show: false,
          title: "资源管理",
          activeMenu: "/course",
          nowActiveMenu: "/course/edit/courseResource",
          nowMenu: "/course/list",
        },
        component: () => import("@/views/course/EditCourseResource.vue"),
      },
      // 2024目标体系新路由
      {
        path: "/course/objectiveIndex",
        name: "ObjectiveIndex",
        meta: {
          show: false,// 是否显示
          title: "目标体系", // 显示的名字
          activeMenu: "/course", // 高亮的顶部菜单
          nowActiveMenu: "/course/objectiveIndex",
          nowMenu: "/course/list",
        },
        component: () => import("@/views/objective/ObjectIndex"),
      },
      // 目标体系 （20240218修改不在左侧显示，不再使用，暂时保留）
      {
        path: "/objective",
        meta: {
          show: false,// 是否显示
          icon: "collection",
          title: "目标体系", // 显示的名字
          activeMenu: "/course", // 高亮的顶部菜单
        },
        redirect: "/objective/contentStrand",
        component: () => import("@/views/course/SubIndex"),
        children: [
          {
            path: "/objective/contentStrand",
            name: "contentStrand",
            meta: {
              show: true,
              title: "目标领域管理",
              activeMenu: "/course",
              nowActiveMenu: "/objective/contentStrand",
              nowMenu: "/objective/contentStrand",
            },
            component: () => import("@/views/objective/ContentStrand"),
          },{
            path: "/objective/Overview",
            name: "Overview",
            meta: {
              show: true,
              title: "概览",
              activeMenu: "/course",
              nowActiveMenu: "/objective/Overview",
              nowMenu: "/objective/Overview",
             },
            component: () => import("@/views/objective/Overview"),
            },{
            path: "/objective/keyExp",
            name: "KeyExp",
            meta: {
              show: true,
              title: "关键经验管理",
              activeMenu: "/course",
              nowActiveMenu: "/objective/keyExp",
              nowMenu: "/objective/keyExp",
            },
            component: () => import("@/views/objective/KeyExp"),
          },
          {
            path: "/objective/LearningTrajectory",
            name: "LearningTrajectory",
            meta: {
              show: true,
              title: "教-学路径管理",
              activeMenu: "/course",
              nowActiveMenu: "/objective/LearningTrajectory",
              nowMenu: "/objective/LearningTrajectory",
            },
            component: () => import("@/views/objective/LearningTrajectory"),
          }
        ],
      },
      // 学习中心
      {
        path: "/course/learnCenter",
        name:'SubIndex',
        meta: {
          show: true,// 是否显示
          icon: "files",
          title: "学习中心", // 显示的名字
          activeMenu: "/course", // 高亮的顶部菜单
        },
        redirect: "/course/learn",
        component: () => import("@/views/course/SubIndex"),
        children: [
          {
            path: "/course/learn",
            name: "LearnIndex",
            meta: {
              show: true,
              icon: "course",
              title: "栏目分类管理",
              activeMenu: "/course",
              nowActiveMenu: "/course/learn",
              nowMenu: "/course/learn",
            },
            component: () => import("@/views/learn/Index"),
          },{
            path: "/course/learn/column",
            name: "LearnColumn",
            meta: {
              show: true,
              icon: "course",
              title: "栏目管理",
              activeMenu: "/course",
              nowActiveMenu: "/course/learn/column",
              nowMenu: "/course/learn/column",
            },
            component: () => import("@/views/learn/Column"),
          },
          {
            path: "/course/learnArticle",
            name: "LearnArticle",
            meta: {
              show: true,
              title: "文章管理",
              activeMenu: "/course",
              nowActiveMenu: "/course/learnArticle",
              nowMenu: "/course/learnArticle",
            },
            component: () => import("@/views/course/LearnArticle"),
          }
        ],
      },
      // 标签系统
      {
        path: "/tag/dimension",
        name:'dimension',
        meta: {
          show: true,// 是否显示
          icon: "files",
          title: "标签系统", // 显示的名字
          activeMenu: "/course", // 高亮的顶部菜单
        },
        redirect: "/tag/dimension",
        component: () => import("@/views/tag/Index"),
        children: [
          {
            path: "/tag/dimension",
            name: "dimension",
            meta: {
              show: true,
              icon: "course",
              title: "标签维度管理",
              activeMenu: "/course",
              nowActiveMenu: "/tag/dimension",
              nowMenu: "/tag/dimension",
            },
            component: () => import("@/views/tag/Dimension"),
          }, {
            path: "/tag/tag",
            name: "Tag",
            meta: {
              show: true,
              icon: "course",
              title: "标签管理",
              activeMenu: "/course",
              nowActiveMenu: "/tag/tag",
              nowMenu: "/tag/tag",
            },
            component: () => import("@/views/tag/Tag"),
          }
        ],
      },
      // 2024-11 开放资源
      {
        path: "/open/sector",
        name:'Sector',
        meta: {
          show: true,// 是否显示
          icon: "files",
          title: "开放资源", // 显示的名字
          activeMenu: "/course", // 高亮的顶部菜单
        },
        redirect: "/open/sector",
        component: () => import("@/views/open/Index"),
        children: [
          {
            path: "/open/sector",
            name: "Sector",
            meta: {
              show: true,
              icon: "course",
              title: "板块管理",
              activeMenu: "/course",
              nowActiveMenu: "/open/sector",
              nowMenu: "/open/sector",
            },
            component: () => import("@/views/open/Sector"),
          }, {
            path: "/open/sectorContent",
            name: "SectorContent",
            meta: {
              show: true,
              icon: "course",
              title: "板块内容管理",
              activeMenu: "/course",
              nowActiveMenu: "/open/sectorContent",
              nowMenu: "/open/sectorContent",
            },
            component: () => import("@/views/open/SectorContent"),
          }
        ],
      },
    ],
  },

  //业务（原来名字-业务管理）
  {
    path: "/customer",
    name: "CustomerIndex",
    component: () => import("@/views/customer/Index"),
    hidden: false,
    meta: {
      title: "业务",
      showInbreadcrumb: true,
    },
    redirect: "/customer/user",
    children: [
      // 用户管理
      {
        path: "/customer/user",
        name: "User",
        meta: {
          show: true,
          icon: "user",
          title: "用户管理",
          activeMenu: "/customer",
          nowActiveMenu: "/customer/user",
          nowMenu: "/customer/user",
        },
        component: () => import("@/views/user/User"),
      },
      {
        path: "/customer/organization",
        name: "Organization",
        meta: {
          show: false,
          icon: "school",
          title: "机构管理",
          activeMenu: "/customer",
          nowActiveMenu: "/customer/organization",
          nowMenu: "/customer/organization",
        },
        component: () => import("@/views/customer/Organization"),
      },
      // 客户中心
      {
        path: "/customer/sustomerCenter",
        name: "CustomerCenter",
        meta: {
          show: true,// 是否显示
          icon: "users",
          title: "客户中心", // 显示的名字
          activeMenu: "/customer", // 高亮的顶部菜单
          // nowActiveMenu: "/customer/CustomerCenter",
          // nowMenu: "/customer/CustomerCenter/organization",
        },
        redirect: "/CustomerCenter/organization",
        component: () => import("@/views/customer/CustomerCenter"),
        children: [
          {
            path: "/CustomerCenter/organization",
            name: "Organization",
            meta: {
              show: true,
              icon: "school",
              title: "机构管理",
              activeMenu: "/customer",
              nowActiveMenu: "/CustomerCenter/organization",
              nowMenu: "/CustomerCenter/organization",
            },
            component: () => import("@/views/customer/Organization"),
          }
        ],
      },
      {
        path: "/customer/manage",
        name: "Manage",
        meta: {
          show: false,
          title: "管理",
          activeMenu: "/customer",
          nowActiveMenu: "/customer/manage",
          nowMenu: "/CustomerCenter/organization",
        },
        redirect: "/customer/manage/member",
        component: () => import("@/views/customer/Manage"),
        children: [
          {
            path: "/customer/manage/member",
            name: "Member",
            meta: {
              show: true,
              title: "成员管理",
              activeMenu: "/customer",
              nowActiveMenu: "/CustomerCenter/organization",
              nowMenu: "/CustomerCenter/organization",
            },
            component: () => import("@/views/customer/Member"),
          },
          {
            path: "/customer/manage/principal",
            name: "Principal",
            meta: {
              show: true,
              title: "负责人管理",
              activeMenu: "/customer",
              nowActiveMenu: "/CustomerCenter/organization",
              nowMenu: "/CustomerCenter/organization",
            },
            component: () => import("@/views/customer/Principal"),
          },
          {
            path: "/customer/manage/teacher",
            name: "Teacher",
            meta: {
              show: true,
              title: "教师管理",
              activeMenu: "/customer",
              nowActiveMenu: "/CustomerCenter/organization",
              nowMenu: "/CustomerCenter/organization",
            },
            component: () => import("@/views/customer/Teacher"),
          },
          {
            path: "/customer/manage/order",
            name: "Order",
            meta: {
              show: true,
              title: "订单管理",
              activeMenu: "/customer",
              nowActiveMenu: "/CustomerCenter/organization",
              nowMenu: "/CustomerCenter/organization",
            },
            component: () => import("@/views/customer/Order"),
          },
          {
            path: "/customer/manage/auth",
            name: "Auth",
            meta: {
              show: true,
              title: "授权管理",
              activeMenu: "/customer",
              nowActiveMenu: "/CustomerCenter/organization",
              nowMenu: "/CustomerCenter/organization",
            },
            component: () => import("@/views/customer/Auth"),
          },
        ],
      },
      // 数据统计
      {
        path: "/customer/dataStats",
        name: "DataStatistics",
        meta: {
          show: true,// 是否显示
          icon: "data",
          title: "数据分析", // 显示的名字
          activeMenu: "/customer", // 高亮的顶部菜单
        },
        redirect: "/dataStats/organization",
        component: () => import("@/views/customer/CustomerCenter"),
        children: [
          {
            path: "/dataStats/base",
            name: "DataBase",
            meta: {
              show: true,
              icon: "school",
              title: "基础数据",
              activeMenu: "/customer",
              nowActiveMenu: "/dataStats/base",
              nowMenu: "/dataStats/base",
            },
            component: () => import("@/views/customer/DataBase"),
          },
          {
            path: "/dataStats/vol",
            name: "DataVol",
            meta: {
              show: true,
              icon: "school",
              title: "火山数据",
              activeMenu: "/customer",
              nowActiveMenu: "/dataStats/vol",
              nowMenu: "/dataStats/vol",
            },
            component: () => import("@/views/customer/DataVol"),
          },
          {
            path: "/dataStats/organization",
            name: "DataGarden",
            meta: {
              show: false,
              icon: "school",
              title: "园所统计",
              activeMenu: "/customer",
              nowActiveMenu: "/dataStats/organization",
              nowMenu: "/dataStats/organization",
            },
            component: () => import("@/views/customer/DataGarden"),
          },
          {
            path: "/dataStats/auth",
            name: "DataAuth",
            meta: {
              show: false,
              icon: "school",
              title: "授权统计",
              activeMenu: "/customer",
              nowActiveMenu: "/dataStats/auth",
              nowMenu: "/dataStats/auth",
            },
            component: () => import("@/views/customer/DataAuth"),
          },
          {
            path: "/dataStats/user",
            name: "DataUser",
            meta: {
              show: false,
              icon: "school",
              title: "用户统计",
              activeMenu: "/customer",
              nowActiveMenu: "/dataStats/user",
              nowMenu: "/dataStats/user",
            },
            component: () => import("@/views/customer/DataUser"),
          }
        ],
      },
      // 软件管理
      {
        path: "/software/index",
        name: "Software",
        meta: {
          show: true,// 是否显示
          icon: "Software",
          title: "软件管理", // 显示的名字
          activeMenu: "/customer", // 高亮的顶部菜单
        },
        redirect: "/software/offline/soft",
        component: () => import("@/views/customer/CustomerCenter"),
        children: [
          {
            path: "/software/offline/soft",
            name: "OfflineSoft",
            meta: {
              show: true,
              icon: "Software",
              title: "离线软件",
              activeMenu: "/customer", // 高亮的顶部菜单
              nowActiveMenu: "/software/offline/soft",
              nowMenu: "/software/offline/soft",

            },
            component: () => import("@/views/software/OfflineSoft"),
          },
          {
            path: "/software/offline/tools",
            name: "OfflineTools",
            meta: {
              show: true,
              icon: "Software",
              title: "离线工具包",
              activeMenu: "/customer", // 高亮的顶部菜单
              nowActiveMenu: "/software/offline/tools",
              nowMenu: "/software/offline/tools",

            },
            component: () => import("@/views/software/OfflineTools"),
          }
        ],
      },
      //帮助
      {
        path: "/help",
        name: "HelpIndex",
        component: () => import("@/views/help/Index"),
        meta: {
          show: true,// 是否显示
          icon: "QuestionFilled",
          title: "帮助中心",
          showInbreadcrumb: true,
          activeMenu: "/customer", // 高亮的顶部菜单
        },
        redirect: "/help/list",
        children: [
          //帮助中心的分类管理
          {
            path: "/help/type",
            name: "HelpType",
            meta: {
              show: true,
              icon: "course",
              title: "主题分类管理",
              activeMenu: "/customer", // 高亮的顶部菜单
              nowActiveMenu: "/help/type",
              nowMenu: "/help/type",
            },
            component: () => import("@/views/help/HelpType"),
          },
          {//帮助管理
            path: "/help/list",
            name: "HelpList",
            meta: {
              show: true,
              icon: "course",
              title: "帮助管理",
              activeMenu: "/customer", // 高亮的顶部菜单
              nowActiveMenu: "/help/list",
              nowMenu: "/help/list",
            },
            component: () => import("@/views/help/HelpList"),
          },
          {//反馈管理
            path: "/help/feedback/list",
            name: "FeedbackList",
            meta: {
              show: true,
              icon: "course",
              title: "反馈管理",
              activeMenu: "/customer", // 高亮的顶部菜单
              nowActiveMenu: "/help/feedback/list",
              nowMenu: "/help/feedback/list",
            },
            component: () => import("@/views/help/FeedbackList"),
          }
        ],
      },


    ],
  },

  // 账号中心
  {
    path: "/account",
    name: "accountIndex",
    component: () => import("@/views/account/Index"),
    hidden: true,
    meta: {
      title: "账号中心",
    },
    redirect: "/account/basicInfo",
    children: [
      // 基本信息
      {
        path: "/account/basicInfo",
        name: "BasicInfo",
        meta: {
          title: "基本信息",
        },
        component: () => import("@/views/account/BasicInfo"),
      },
      // 安全设置
      {
        path: "/account/security",
        name: "SecuritySetting",
        meta: {
          title: "安全设置",
        },
        component: () => import("@/views/account/SecuritySetting"),
      },
      // 通知消息
      {
        path: "/account/notice",
        name: "Notice",
        meta: {
          title: "通知消息",
        },
        component: () => import("@/views/account/Notice"),
      },
      // 操作记录
      {
        path: "/account/operationLog",
        name: "OperationLog",
        meta: {
          title: "操作记录",
        },
        component: () => import("@/views/account/OperationLog"),
      },

    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  publicPath: "/",
});

const whiteList = ["/login", "/register"]; // no redirect whitelist
const agent = window.navigator.userAgent.toLowerCase();
router.beforeEach((to, from, next) => {
  //如果用户访问的是登录页，则直接放行
  if (to.path === "/" || to.path === "/login") return next();
  //从 localStorage 中获取到保存的 token 值
  const tokenStr = window.localStorage.getItem("nof_token");
  //如果没有 token 强制跳转到登录页
  if (!tokenStr) return next("/login");
  next();
});

export default router;
