import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.slice.js";
import '../../../public/UEditor/ueditor.config.js';
import '../../../public/UEditor/ueditor.all.js';
import '../../../public/UEditor/lang/zh-cn/zh-cn.js';
import jquery from 'jquery';
import { qn_token_get, uploadFile } from '../../api/qiniuApI';
export default {
  name: '',
  props: {
    // 初始化内容和实例化编辑器的名称id，由父组件传入，这里也会有一个默认的初始值
    defaultMsg: {
      type: String,
      default: '请输入内容'
    },
    id: {
      type: String,
      default: "ue".concat(Math.random(0, 100))
    },
    drawerData: {
      type: String
    },
    articleData: {
      type: Object
    },
    modeType: {
      type: String
    },
    zIndex: {
      type: Number,
      default: 1010
    },
    itemName: {
      type: String,
      default: 'description'
    }
  },
  data: function data() {
    return {
      columnId: '',
      modelList: [
      //图文环绕 又环绕
      {
        "preHtml": '<section class="editor"><section style="width:100%;;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px; ;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;"><section style="font-size:14px;width:100%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;padding:20px;"><p style="float:right;margin-left:10px; zoom: 0.3;""><img style="display: block;" src="https://7.onefun.com.cn/resource/logo/1713144718038/lietu.jpeg"/></p><section>恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈..时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了。</section><section style="width: 0px; height: 0px; clear: both;"></section></section></section></section>',
        "html": '<section class="editor"><section style="width:100%;;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px; ;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;"><section style="font-size:14px;width:100%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;padding:10px;"><p style="float:right;margin-left:20px;"><img style="display: block;" src="https://7.onefun.com.cn/resource/logo/1713144718038/lietu.jpeg"/></p><section>恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈..时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了。</section><section style="width: 0px; height: 0px; clear: both;"></section></section></section></section>'
      },
      //图文环绕 左环绕
      {
        "preHtml": '<section class="editor"><section style="width:100%;;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px; ;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;"><section style="font-size:14px;width:100%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;padding:10px;"><p style="float:left;margin-right:20px; display: block; zoom: 0.3;"><img style="display: block;" src="https://7.onefun.com.cn/resource/logo/1713144718038/lietu.jpeg"/></p><section>恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈..时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了。</section><section style="width: 0px; height: 0px; clear: both;"></section></section></section></section>',
        "html": '<section class="editor"><section style="width:100%;;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px; ;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;"><section style="font-size:14px;width:100%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;padding:10px;"><p style="float:left;margin-right:20px; display: block;"><img style="display: block;" src="https://7.onefun.com.cn/resource/logo/1713144718038/lietu.jpeg"/></p><section>恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈...时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了恋爱跟火同样的贪滥，同样的会蔓延，同样的残忍，消灭了监牢结实的原料，把灰烬去换光明和热烈..时间对友谊的磨蚀，好比水流过石子，反而把它洗濯的光洁了。</section><section style="width: 0px; height: 0px; clear: both;"></section></section></section></section>'
      }, {
        preHtml: '<section class="editor"><p style="box-sizing:border-box;width:150px;display:flex;align-items:center;height:36px;padding:0 25px 0 22px;line-height:36px;border-radius:18px;color:#fff;font-weight:bold;background-color:#00C0CE;"><img style="width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1.png" alt=""><strong style="font-size:18px;font-weight: bold;">活动目标</strong></p></section>',
        html: '<section class="editor"><p style="box-sizing:border-box;width:150px;display:flex;align-items:center;height:36px;padding:0 25px 0 22px;line-height:36px;border-radius:18px;color:#fff;font-weight:bold;background-color:#00C0CE;margin-bottom:8px;"><img style="width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1.png" alt=""><strong style="font-size:18px;font-weight: bold;">活动目标</strong></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="display:flex;align-items:center;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1-1.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#00C0CE;">认知性目标</span></p></section>',
        html: '<section class="editor"><p style="display:flex;align-items:center;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1-1.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#00C0CE;">认知性目标</span></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="display:flex;align-items:center;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1-2.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#00C0CE;">过程性能力目标</span></p></section>',
        html: '<section class="editor"><p style="display:flex;align-items:center;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1-2.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#00C0CE;">过程性能力目标</span></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="display:flex;align-items:center;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1-3.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#00C0CE;">学习品质目标</span></p></section>',
        html: '<section class="editor"><p style="display:flex;align-items:center;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic1-3.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#00C0CE;">学习品质目标</span></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="box-sizing:border-box;width:150px;display:flex;align-items:center;height:36px;padding:0 25px 0 22px;line-height:36px;border-radius:18px;color:#fff;font-weight:bold;background-color:#D2B617;margin-top:12px;margin-bottom:6px;"><img style="width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic2.png" alt=""><strong style="font-size:18px;font-weight: bold;">活动准备</strong></p></section>',
        html: '<section class="editor"><p style="box-sizing:border-box;width:150px;display:flex;align-items:center;height:36px;padding:0 25px 0 22px;line-height:36px;border-radius:18px;color:#fff;font-weight:bold;background-color:#D2B617;margin-top:12px;margin-bottom:8px;"><img style="width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic2.png" alt=""><strong style="font-size:18px;font-weight: bold;">活动准备</strong></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="display:flex;align-items:center;margin-top:6px;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic2-1.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#D2B617;">自备材料</span></p></section>',
        html: '<section class="editor"><p style="display:flex;align-items:center;margin-top:6px;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic2-1.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#D2B617;">自备材料</span></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="display:flex;align-items:center;margin-top:6px;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic2-2.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#D2B617;">打印材料</span></p></section>',
        html: '<section class="editor"><p style="display:flex;align-items:center;margin-top:6px;margin-bottom:6px;"><img style=" vertical-align: middle;width:22px;height:22px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/gl-ic2-2.png" alt=""><span style="flex:1;font-size:18px;font-weight: bold;color:#D2B617;">打印材料</span></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="align-items:center;overflow: hidden;margin-left:32px;"><img style="width:20px;height:20px;margin-right:8px;display:inline-block;float:left;" src="https://7.onefun.com.cn/article/icon/gl-icdg.png" alt=""><span style="float:left;font-size:16px;width:calc(100% - 28px);">无需数列内容</span></p></section>',
        html: '<section class="editor"><p style="align-items:center;overflow: hidden;margin-left:32px;margin-bottom:6px;"><img style="width:20px;height:20px;margin-top:3px;margin-right:8px;display:inline-block;float:left;" src="https://7.onefun.com.cn/article/icon/gl-icdg.png" alt=""><span style="float:left;font-size:16px;width:calc(100% - 28px);">无需数列内容</span></p></section>'
      }, {
        preHtml: '<section class="editor"><p style="align-items:center;overflow: hidden;margin-left:32px;"><span style="float:left;display: inline-block;width:20px;height:20px;color:#fff;font-weight:bold;border-radius: 50%;background:#D8D8D8;text-align: center;line-height: 20px; margin-right:8px;font-size:12px;">1</span><span style="font-size:16px;float:left;width:calc(100% - 28px);">有序数列数字列表</span></p></section>',
        html: '<section class="editor"><p style="align-items:center;overflow: hidden;margin-left:32px;margin-bottom:6px;"><span style="float:left;display: inline-block;width:20px;height:20px;color:#fff;font-weight:bold;border-radius: 50%;background:#D8D8D8;text-align: center;line-height: 20px; margin-top:3px;margin-right:8px;font-size:12px;">1</span><span style="font-size:16px;float:left;width:calc(100% - 28px);">有序数列数字列表</span></p></section>'
      }],
      modelListTest: [{
        html: ' <section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;align-items:center;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#08AE9E;"><img style="width:24px;height:24px;margin-right:8px;display:inline-block;" src="http://phwmrnsiz.bkt.clouddn.com/article/image/1695703627505gl-ic1.png" alt=""><strong style="font-size:20px;font-weight: bold;">活动目标</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;align-items:center;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#3DB624;"><img style="width:24px;height:24px;margin-right:8px;display:inline-block;" src="http://phwmrnsiz.bkt.clouddn.com/article/image/1695703627502gl-ic2.png" alt=""><strong style="font-size:20px;font-weight: bold;">活动准备</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;align-items:center;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#DC804D;"><img style="width:24px;height:24px;margin-right:8px;display:inline-block;" src="http://phwmrnsiz.bkt.clouddn.com/article/image/1695703627383gl-ic3.png" alt=""><strong style="font-size:20px;font-weight: bold;">自备材料</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;align-items:center;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#8C8789;"><img style=" vertical-align: middle;;width:24px;height:24px;margin-right:8px;display:inline-block;" src="http://phwmrnsiz.bkt.clouddn.com/article/image/1695703627371gl-ic4.png" alt=""><strong style="font-size:20px;font-weight: bold;">打印材料</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="display:flex;align-items:center;margin:10px 0;"><img style=" vertical-align: middle;;width:24px;height:24px;margin-right:8px;display:inline-block;" src="http://phwmrnsiz.bkt.clouddn.com/article/image/1695703627362gl-icdg.png" alt=""><span style="flex:1;font-size:16px;">无需数列内容内容内容哈哈哈哈哈哈哈哈</span></p></section>'
      }, {
        html: '<section class="editor"><p style="display:flex;align-items:center;margin:10px 0;"><span style="display: inline-block;width:24px;height:24px;color:#fff;font-weight:bold;border-radius: 50%;background:#D8D8D8;text-align: center;line-height: 24px; margin-right:8px;">2</span><span style="flex:1;font-size:16px;">有序数列数字列表嘿嘿哈哈哈哈哈哈哈哈</span></p></section>'
      }],
      modelListOld: [{
        html: ' <section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#08AE9E;"><img style="width:24px;height:24px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/1696670371242gl-ic1.png" alt=""><strong style="font-size:20px;font-weight: bold;">活动目标</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;align-items:center;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#3DB624;"><img style="width:24px;height:24px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/1696670371251gl-ic2.png" alt=""><strong style="font-size:20px;font-weight: bold;">活动准备</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;align-items:center;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#DC804D;"><img style="width:24px;height:24px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/1696670371263gl-ic3.png" alt=""><strong style="font-size:20px;font-weight: bold;">自备材料</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="box-sizing:border-box;width:165px;display:flex;align-items:center;height:40px;padding:0 30px 0 20px;line-height:40px;border-radius:20px;color:#fff;font-weight:bold;background-color:#8C8789;"><img style=" vertical-align: middle;;width:24px;height:24px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/1696670371451gl-ic4.png" alt=""><strong style="font-size:20px;font-weight: bold;">打印材料</strong></p></section>'
      }, {
        html: '<section class="editor"><p style="display:flex;align-items:center;margin:10px 0;"><img style=" vertical-align: middle;;width:24px;height:24px;margin-right:8px;display:inline-block;" src="https://7.onefun.com.cn/article/icon/1696670371461gl-icdg.png" alt=""><span style="flex:1;font-size:16px;">无需数列内容内容内容哈哈哈哈哈哈哈哈</span></p></section>'
      }, {
        html: '<section class="editor"><p style="display:flex;align-items:center;margin:10px 0;"><span style="display: inline-block;width:24px;height:24px;color:#fff;font-weight:bold;border-radius: 50%;background:#D8D8D8;text-align: center;line-height: 24px; margin-right:8px;">2</span><span style="flex:1;font-size:16px;">有序数列数字列表嘿嘿哈哈哈哈哈哈哈哈</span></p></section>'
      }],
      valueHtml: "",
      data: "",
      editor: null,
      editorConfig: {
        // 设置编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        //initialFrameHeight: '100%',
        // 初始容器宽度
        initialFrameWidth: "800",
        // 可以放后台存放路径
        // serverUrl: "https://upload.qiniu.com/",
        serverUrl: "",
        // serverUrl: "http://192.168.0.200:9031/editor/upload",
        // serverUrl: "http://localhost:8724/UEditor/php/controller.php",
        // UEditor 是文件的存放路径，
        UEDITOR_HOME_URL: "/UEditor/",
        // autoFloatEnabled: true,
        zIndex: 99999,
        //清空了工具栏
        toolbars: [['undo',
        //撤销
        'redo',
        //重做
        'fontfamily',
        //字体
        'fontsize',
        //字号
        'forecolor', 'backcolor', 'customstyle',
        //自定义标题
        'forecolor', 'backcolor',
        // 'paragraph', //段落格式
        // 'simpleupload', //单图上传
        'insertimage',
        //多图上传
        'insertvideo',
        //视频
        'bold',
        //加粗
        'italic',
        //斜体
        'underline',
        //下划线
        'justifyleft',
        //居左对齐
        'justifyright',
        //居右对齐
        'justifycenter',
        //居中对齐
        'justifyjustify',
        //两端对齐
        'lineheight',
        //行间距
        'insertorderedlist',
        //有序列表
        'insertunorderedlist',
        //无序列表
        'link',
        //超链接
        'attachment',
        //附件
        'inserttable',
        //插入表格

        // 'indent', //首行缩进
        'source',
        //源代码
        'cleardoc' //清空文档
        // 'template', //模板
        ]],

        qnToken: '',
        imgUrl: '',
        "imageActionName": "image",
        /* 执行上传图片的action名称 */
        "imageFieldName": "file",
        /* 提交的图片表单名称 */
        "imageMaxSize": 204800000,
        /* 上传大小限制，单位B */
        "imageAllowFiles": [".png", ".jpg", ".jpeg", ".gif", ".bmp"],
        /* 上传图片格式显示 */
        "imageCompressEnable": true,
        /* 是否压缩图片,默认是true */
        "imageCompressBorder": 1600,
        /* 图片压缩最长边限制 */
        "imageInsertAlign": "none",
        /* 插入的图片浮动方式 */
        "imageUrlPrefix": "",
        /* 图片访问路径前缀 */
        "imagePathFormat": "upload/image/{yyyy}{mm}{dd}/{time}{rand:6}",
        /* 上传视频配置 */
        "videoActionName": "uploadvideo",
        /* 执行上传视频的action名称 */
        "videoFieldName": "file",
        /* 提交的视频表单名称 */
        "videoPathFormat": "/ueditor/php/upload/video/{yyyy}{mm}{dd}/{time}{rand:6}",
        /* 上传保存路径,可以自定义保存路径和文件名格式 */
        "videoUrlPrefix": "",
        /* 视频访问路径前缀 */
        "videoMaxSize": 2048000000,
        /* 上传大小限制，单位B */
        "videoAllowFiles": [".flv", ".swf", ".mkv", ".avi", ".rm", ".rmvb", ".mpeg", ".mpg", ".ogg", ".ogv", ".mov", ".wmv", ".mp4", ".webm", ".mp3", ".wav", ".mid"],
        /* 上传视频格式显示 */
        /* 上传文件配置 */
        "fileActionName": "uploadfile",
        /* controller里,执行上传视频的action名称 */
        "fileFieldName": "file",
        /* 提交的文件表单名称 */
        "filePathFormat": "/ueditor/php/upload/file/{yyyy}{mm}{dd}/{time}{rand:6}",
        /* 上传保存路径,可以自定义保存路径和文件名格式 */
        "fileUrlPrefix": "",
        /* 文件访问路径前缀 */
        "fileMaxSize": 2048000000,
        /* 上传大小限制，单位B，默认50MB */
        "fileAllowFiles": [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".flv", ".swf", ".mkv", ".avi", ".rm", ".rmvb", ".mpeg", ".mpg", ".ogg", ".ogv", ".mov", ".wmv", ".mp4", ".webm", ".mp3", ".wav", ".mid", ".rar", ".zip", ".tar", ".gz", ".7z", ".bz2", ".cab", ".iso", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".pdf", ".txt", ".md", ".xml"] /* 上传文件格式显示 */
      },

      editTitle: '',
      description: '',
      isShowStateMenu: false,
      nowMuluTab: 0,
      muluListState: true,
      muluList: [],
      id: Math.random(10) + 'ueditorId',
      iframe: '',
      iframeDocument: '',
      oldCatalogs: [],
      completeVisible: false,
      completeDrawer: '',
      completeFormFile: {
        rank: '',
        articleState: 1,
        cover: ''
      },
      action: '',
      urlPrefix: '',
      setPopupState: 'active',
      file: ''
    };
  },
  computed: {
    // 滚动区高度
    editorHeight: function editorHeight() {
      return document.body.clientHeight - 117 + 'px'; //自定义高度需求
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    window.addEventListener('resize', this.handleResize);
    if (this.modeType === 'article') {
      // 文章编辑
      if (Object.keys(this.articleData).length !== 0) {
        this.valueHtml = this.articleData.content;
        this.data = this.articleData.content;
        this.editTitle = this.articleData.title;
        this.columnId = this.articleData.columnId;
        this.completeFormFile = {
          rank: this.articleData.rank,
          articleState: this.articleData.state,
          cover: this.articleData.cover
        };
      } else {
        this.columnId = this.$parent.currentTree.id || 0;
      }
    } else {
      // 描述
      this.data = this.articleData.content;
    }
    qn_token_get().then(function (tokenData) {
      // if (process.env.NODE_ENV == "production"){
      //   //正式
      //   this.action="https://upload.qiniup.com/"
      //   this.urlPrefix = 'https://7.onefun.com.cn/'

      //测试
      /* this.action="https://upload-z1.qiniup.com/"
       this.urlPrefix = 'http://phwmrnsiz.bkt.clouddn.com/'*/
      // }else{
      //   //测试
      //   this.action="https://upload-z1.qiniup.com/"
      //   this.urlPrefix = 'http://phwmrnsiz.bkt.clouddn.com/'
      // }

      _this2.action = process.env.VUE_APP_QINIU_UPLOAD;
      _this2.urlPrefix = process.env.VUE_APP_QINIU_PREFIX;
      _this2.editorConfig.imgUrl = _this2.action;
      _this2.editorConfig.imageUrlPrefix = _this2.urlPrefix;
      _this2.editorConfig.videoUrlPrefix = _this2.urlPrefix;
      _this2.editorConfig.fileUrlPrefix = _this2.urlPrefix;
      var token = tokenData.token;
      _this2.editorConfig.qnToken = token;
      _this2.$nextTick(function () {
        var that = _this2;
        _this2.editor = window.UE.getEditor(_this2.id, _this2.editorConfig);
        _this2.editor.focus();
        _this2.editor.ready(function () {
          that.iframe = document.getElementsByTagName('iframe')[0];
          // 获取 iframe 中的 document 对象
          that.iframeDocument = _this2.iframe.contentDocument;
          if (that.data) {
            that.editor.setContent(that.data);
            var htmlEleArry = that._createElemByHTML(that.data);
            that.getCatalogs(htmlEleArry);
          }
          that.editor.addListener('contentChange', function () {
            var html = _this2.editor.getContent();
            _this2.data = html;
            var htmlEleArry = _this2._createElemByHTML(html);
            that.getCatalogs(htmlEleArry);
            // const wordCount = this.editor.getContentLength(true)
          });

          that.editor.addListener('selectionchange', function () {});
          that.$emit('ready', _this2.editor);
          console.log(_this2.editor);
        });

        // var filetype = this.editor.getOpt('filetype');
        var ActionName = _this2.editor.getOpt('qnToken');
        // alert(ActionName)
        // uploadimage
      });
    });
  },

  methods: {
    saveBack: function saveBack() {
      var html = this.editor.getContent();
      //如果有传drawerData字段，就用传的，没有就写死
      if (this.drawerData) {
        this.$parent[this.drawerData][this.itemName] = html;
      } else {
        this.$parent.drawerData[this.itemName] = html;
      }
      this.$parent.isEditorShow = false;
    },
    // 改变编辑器的高度
    handleResize: function handleResize() {
      // this.$refs.editorMain.style.height =  (document.body.clientHeight - 117) + 'px'
    },
    // 模版插入编辑器
    insertEditorFun: function insertEditorFun(insertWord) {
      this.editor.execCommand("inserthtml", insertWord);
    },
    // 关闭函数
    cancelCloseFn: function cancelCloseFn() {
      this.$parent.nowArticleData = {};
      this.completeFormFile = {
        rank: '',
        articleState: 1,
        cover: ''
      };
      this.$parent.isEditorShow = false;
    },
    // 文档设置
    setFn: function setFn() {
      // 主动点击文档设置
      this.setPopupState = 'active';
      this.completeVisible = true;
    },
    // 文档设置弹框-取消函数
    completeSetCancelFun: function completeSetCancelFun() {
      this.completeVisible = false;
    },
    // 文档设置弹框-确定函数
    completeSetFn: function completeSetFn() {
      if (!this.completeFormFile.rank) {
        this.$toast.error('排列顺序不能为0，请选择～');
        return;
      }
      if (this.setPopupState === 'passive') {
        this.CompleteFn();
      }
      this.completeVisible = false;
    },
    fileFun: function fileFun() {
      var _this = this;
      if (this.file) {
        uploadFile({
          folderName: "article",
          complete: function complete(res, url) {
            _this.completeFormFile.cover = url;
            if (Object.keys(_this.articleData).length !== 0) {
              _this.EditArticleApi();
            } else {
              _this.completeApi();
            }
          }
        }, this.file);
      } else {
        if (Object.keys(this.articleData).length !== 0) {
          _this.EditArticleApi();
        } else {
          _this.completeApi();
        }
      }
    },
    completeApi: function completeApi() {
      /**
       * userId,roleId,columnId,title,content,description,rank,state
       * **/
      var that = this;
      this.service.axiosUtils({
        requestName: 'articleAddArticle',
        data: {
          columnId: this.columnId,
          title: this.editTitle,
          content: this.data,
          description: this.description,
          rank: this.completeFormFile.rank,
          state: this.completeFormFile.articleState,
          cover: this.completeFormFile.cover
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.cancelCloseFn();
            that.$parent.initData();
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    },
    // 编辑接口
    EditArticleApi: function EditArticleApi() {
      /**
       * userId,roleId,id,columnId,title,content,description,rank,state
       * **/
      var that = this;
      this.service.axiosUtils({
        requestName: 'articlesEditArticle',
        data: {
          id: this.articleData.id,
          columnId: this.columnId,
          title: this.editTitle,
          content: this.data,
          description: '',
          rank: this.completeFormFile.rank,
          state: this.completeFormFile.articleState,
          cover: this.completeFormFile.cover
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.cancelCloseFn();
            that.$parent.initData();
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    },
    // 完成提交
    CompleteFn: function CompleteFn() {
      if (!this.editTitle) {
        this.$toast.error('您未输入标题～');
        return;
      }
      if (!this.data) {
        this.$toast.error('您未输入内容～');
        return;
      }
      // 没有填写顺序
      if (!this.completeFormFile.rank) {
        this.$toast.error('您未进行文档顺序设置，请操作～');
        // 被动出现文档设置弹框
        this.setPopupState = 'passive';
        this.completeVisible = true;
        return;
      }
      this.fileFun();

      // if(Object.keys(this.articleData).length !== 0){
      //   this.EditArticleApi()
      // }else{
      //   this.completeApi()
      // }
    },

    // 目录树是否显示
    closeMuluFun: function closeMuluFun() {
      if (this.muluListState) {
        this.muluListState = false;
      } else {
        this.muluListState = true;
      }
    },
    // 显示目录树
    showMuluFun: function showMuluFun() {
      this.muluListState = true;
    },
    // 目录每一项点击函数-与编辑器滚动呼应，滚动到指定位置
    muluItemFun: function muluItemFun(index) {
      this.nowMuluTab = index;
    },
    // 展开折叠目录树
    hideShowTreeFun: function hideShowTreeFun(val, index) {
      if (val.showIcon) {
        if (val.hideOrshow === 'hide') {
          val.hideOrshow = 'show';
        } else {
          val.hideOrshow = 'hide';
        }
        for (var i = index; i < this.muluList.length - 1; i++) {
          if (this.muluList[index].level >= this.muluList[i + 1].level) return;
          if (val.hideOrshow === 'show') {
            if (this.muluList[i + 1].level > this.muluList[index].level) {
              this.muluList[i + 1].isShowState = false;
            }
          } else {
            if (this.muluList[i + 1].level > this.muluList[index].level) {
              this.muluList[i + 1].isShowState = true;
            }
          }
        }
        this.$forceUpdate();
      }
    },
    initTree: function initTree(arr) {
      this.muluList = arr;
      this.muluList.forEach(function (elem, index) {
        elem.isShowState = true;
        elem.level = parseInt(elem.tag.replace("H", "")) - 1;
      });

      // 有无子目录判断
      for (var i = 0; i < this.muluList.length; i++) {
        if (i === this.muluList.length - 1) {
          this.muluList[i].showIcon = false;
        } else {
          if (this.muluList[i].level < this.muluList[i + 1].level) {
            this.muluList[i].showIcon = true;
            this.muluList[i].hideOrshow = 'hide';
          } else {
            this.muluList[i].showIcon = false;
          }
        }
      }
      this.$forceUpdate();
    },
    scrollToHead: function scrollToHead(id) {
      // 在 iframe 中查找元素
      var iframeElement = this.iframeDocument.getElementById(id);
      var targetTop = iframeElement.offsetTop;
      var textElem = jquery(this.editor.window);
      textElem.scrollTop(targetTop);
    },
    getNodeName: function getNodeName(elems) {
      var elem = elems[0];
      return elem.nodeName;
    },
    getCatalogs: function getCatalogs(textElem) {
      var _this3 = this;
      var elems = textElem;
      var catalogs = [];
      elems.forEach(function (elem, index) {
        var $elem = jquery(elem);
        var id = $elem.attr('id');
        var tag = _this3.getNodeName($elem);
        var text = $elem.text();
        if (tag === 'H2' || tag === 'H3' || tag === 'H4') {
          // if (!id) {
          //     id = getRandomCode()
          //     $elem.attr('id', id)
          // }
          // 标题为空的情况不生成目录
          if (!text) return;
          catalogs.push({
            tag: tag,
            id: id,
            text: text
          });
        }
      });
      // 旧目录和新目录对比是否相等，不相等则运行回调并保存新目录到旧目录变量，以方便下一次对比
      if (JSON.stringify(this.oldCatalogs) !== JSON.stringify(catalogs)) {
        this.oldCatalogs = catalogs;
        this.initTree(this.oldCatalogs);
      }
    },
    toArray: function toArray(data) {
      return Array.prototype.slice.call(data);
    },
    _createElemByHTML: function _createElemByHTML(html) {
      var div = document.createElement('div');
      div.innerHTML = html;
      var elems = div.children;
      return this.toArray(elems);
    },
    //修改图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        _this.completeFormFile.cover = this.result;
      };
    }
  },
  destroyed: function destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.editor.destory();
  }
};